// src/App.js

import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import ScrollToTop from './components/ScrollToTop';
import { Elements } from '@stripe/react-stripe-js';
import { CartProvider } from './components/CartContext';
import { stripePromise } from './components/stripe';
import PolicyPage from './components/PolicyPage';
import ErrorBoundary from './components/ErrorBoundary';
import Footer from './components/Footer';
import RedirectToSlug from './components/RedirectToSlug';
import './App.css';

// Lazy Loaded Components
const AboutUs = lazy(() => import('./components/AboutUs'));
const Navigation = lazy(() => import('./components/Navigation'));
const BookPage = lazy(() => import('./components/BookPage'));
const Products = lazy(() => import('./components/Products'));
const NotFound = lazy(() => import('./components/NotFound'));
const CartCheckout = lazy(() => import('./components/CartCheckout'));
const Download = lazy(() => import('./components/Download'));
const ContactPage = lazy(() => import('./components/ContactPage'));
const Blog = lazy(() => import('./components/Blog'));
const Topics = lazy(() => import('./components/Topics'));
const TopicsPost = lazy(() => import('./components/TopicsPost'));
const BlogPost = lazy(() => import('./components/BlogPost'));
const HomePage = lazy(() => import('./components/HomePage')); // Import HomePage

function App() {
  return (
    <HelmetProvider>
      <CartProvider>
        <Router>
          <ScrollToTop />
          <div className="background" />
          <div className="App">
            {/* Navigation */}
            <Suspense fallback={<div>Loading Navigation...</div>}>
              <Navigation />
            </Suspense>

            {/* Main Content with Padding */}
            <div className="main-content">
              <ErrorBoundary>
                <Suspense fallback={<div>Loading Content...</div>}>
                  <Routes>
                    <Route path="/" element={<HomePage />} /> {/* Use HomePage */}
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/shop" element={<Products />} />

                    {/* Redirect from ID-based URLs to Slug-based URLs */}
                    <Route path="/shop/id/:id" element={<RedirectToSlug />} />

                    <Route
                      path="/shop/:slug"
                      element={
                        <Elements stripe={stripePromise}>
                          <BookPage />
                        </Elements>
                      }
                    />
                    <Route
                      path="/cart"
                      element={
                        <Elements stripe={stripePromise}>
                          <CartCheckout />
                        </Elements>
                      }
                    />
                    <Route path="/download" element={<Download />} />
                    <Route path="/contact" element={<ContactPage />} />
                    <Route path="/policy/:policyType" element={<PolicyPage />} />
                    <Route path="/blog" element={<Blog />} />
                    <Route path="/blog/:slug" element={<BlogPost />} />
                    <Route path="/topics" element={<Topics />} />
                    <Route path="/topics/:slug" element={<TopicsPost />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </Suspense>
              </ErrorBoundary>
            </div>

            {/* Footer */}
            <Footer />
          </div>
        </Router>
      </CartProvider>
    </HelmetProvider>
  );
}

export default App;
